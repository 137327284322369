import { useQuery } from "@tanstack/react-query";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { DateObject } from "react-multi-date-picker";
import {
    getFinancialProfitSummary,
    getProfitReport,
    getSubscriptionReport,
    getUserSearch,
    getPartnerCodeReport,
    getVirtualServers,
    getFreelancerCost,
    getExtraCost,
    getVirtualNumber,
    getEmployeeSalary,
    getTicketReport,
    getClosedTicketRepoprt,
    getTicketTimeReport,
    getPartnerShareReport,
    getDepartmentInfraServer,
    getDepartmentFinancial,
    getDepartmentAccounts,
    getUsrsWithdrawals,
    getUsrsIncomes,
} from "services/admin-http-requests";
import {
    getBankInfo,
    getBscWalletBalance,
    getTransactionHistory,
    getUserInfo,
    getWalletBalance,
    leaderboardTopAccountsApi,
    updateTransactionHistory,
} from "services/http-requests";

const useGetUserInfo = () => {
    return useQuery({
        queryKey: "user-info",
        queryFn: () => getUserInfo(),
        staleTime: 5 * 60 * 1000, //5 minutes
        gcTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useWalletBalance = () => {
    return useQuery({
        queryKey: "wallet-balance",
        queryFn: () => getWalletBalance(),
        staleTime: 30 * 60 * 1000, //30 minutes
        gcTime: 30 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useSubscriptionReport = (date, platform, page, zeroAmount) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;
    if (platform.length > 0) {
        platform.map((pl) => {
            query += `&platforms=${pl}`;
        });
    }
    return useQuery({
        queryKey: ["subscription-report", query],
        queryFn: () => getSubscriptionReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useDepartmentInfraServer = (date) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}`;
    return useQuery({
        queryKey: ["department-infraserver-report", query],
        queryFn: () => getDepartmentInfraServer(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useDepartmentFinancial = (date) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}`;
    return useQuery({
        queryKey: ["department-financial-report", query],
        queryFn: () => getDepartmentFinancial(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useDepartmentAccounts = (date) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}`;
    return useQuery({
        queryKey: ["department-accounts-report", query],
        queryFn: () => getDepartmentAccounts(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useBscWalletBalance = () => {
    return useQuery({
        queryKey: "bsc-wallet-balance",
        queryFn: () => getBscWalletBalance(),
        staleTime: 30 * 60 * 1000, //30 minutes
        gcTime: 30 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useUpdateHistory = (enableFlag) => {
    return useQuery({
        queryKey: "update-history",
        queryFn: () => updateTransactionHistory(),
        staleTime: 10 * 60 * 1000, //10 minutes
        gcTime: 30 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: enableFlag,
    });
};

const useTransactionHistory = (date, reasons, type, direction, origin, destination, page) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;
    if (reasons.length > 0) {
        reasons.map((rs) => {
            query += `&reason=${rs}`;
        });
    }
    if (type) {
        query += `&type=${type}`;
    }
    if (direction.length > 0) {
        direction.map((tp) => {
            query += `&inout=${tp}`;
        });
    }
    if (origin) {
        query += `&origin=${origin}`;
    }
    if (destination) {
        query += `&destination=${destination}`;
    }
    return useQuery({
        queryKey: ["transaction-history", query],
        queryFn: () => getTransactionHistory(query),
        staleTime: 30 * 60 * 1000, //30 minutes
        gcTime: 30 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useTickets = (date, levels, departments, ticketId, page, size, tabIndex) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}&page_size=${size}`;
    if (levels.length > 0) {
        levels.map((lv) => {
            query += `&levels=${lv}`;
        });
    }
    if (departments.length > 0) {
        departments.map((dp) => {
            query += `&departments=${dp}`;
        });
    }
    if (ticketId) {
        query += `&ticket_id=${ticketId}`;
    }
    return useQuery({
        queryKey: ["ticket-report", query],
        queryFn: () => getTicketReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: tabIndex === 0 || tabIndex === 5,
    });
};

const useTicketsTime = (date, tabIndex) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}`;
    return useQuery({
        queryKey: ["ticket-time-report", query],
        queryFn: () => getTicketTimeReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4,
    });
};

const useClosedTickets = (date, department) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}`;

    if (department.length > 0) {
        department.map((dp) => {
            query += `&departments=${dp}`;
        });
    }
    return useQuery({
        queryKey: ["closed-ticket-report", query],
        queryFn: () => getClosedTicketRepoprt(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useEmployeeSalary = (date, page, isPaid) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;
    if (isPaid !== undefined) {
        if (isPaid === 3) query += `&is_paid=true`;
        if (isPaid === 2) query += `&is_paid=false`;
    }
    return useQuery({
        queryKey: ["employee-salary", query],
        queryFn: () => getEmployeeSalary(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useFreelancers = (date, platform, page, user, status, zeroAmount) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;
    if (platform.length > 0) {
        platform.map((pl) => {
            query += `&platform_uuids=${pl}`;
        });
    }
    if (user !== undefined) {
        query += `&user_uuid=${user}`;
    }
    if (status !== undefined) {
        switch (status) {
            case 1:
                break;
            case 2:
                query += `&paid_status=0`;
                break;
            case 3:
                query += `&paid_status=1`;
                break;

            default:
                break;
        }
    }
    return useQuery({
        queryKey: ["freelancer-report", query],
        queryFn: () => getFreelancerCost(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

const useUsersWithdrawal = (teacher, cashOutDate, platform, hasCashout, remainingDate, page) => {
    let query = `page=${page}`;
    if (teacher.length > 0) {
        const mnt = teacher.join(",");
        query += `&teacher_uuid=${mnt}`;
    }
    if (platform.length > 0) {
        const plt = platform.join(",");
        query += `&platform_uuid=${plt}`;
    }
    if (hasCashout) {
        if (hasCashout === 1) query += "&has_cashout=true";
        if (hasCashout === 2) query += "&has_cashout=false";
    }
    if (cashOutDate[0]) {
        const startDate = new DateObject({
            date: cashOutDate[0],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        const endDate = new DateObject({
            date: cashOutDate[1],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        query += `&last_cashout_start=${startDate.replaceAll(
            "/",
            "-"
        )}&last_cashout_end=${endDate.replaceAll("/", "-")}`;
    }

    if (remainingDate[0]) {
        query += `&remaining_days_start=${remainingDate[0]}&remaining_days_end=${remainingDate[1]}`;
    }
    return useQuery({
        queryKey: ["users-withdrawal", query],
        queryFn: () => getUsrsWithdrawals(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useUsersIncome = (teacher, page) => {
    let query = `page=${page}`;
    return useQuery({
        queryKey: ["users-withdrawal", query],
        queryFn: () => getUsrsIncomes(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useTopUsers = (platform, startDate, endDate) => {
    let query = `platforms=${platform}&start_time=${startDate}&end_time=${endDate}`;
    return useQuery({
        queryKey: ["top-users", query],
        queryFn: () => leaderboardTopAccountsApi(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: platform !== null,
    });
};
const useVirtualNumber = (date, page) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;

    return useQuery({
        queryKey: ["number", query],
        queryFn: () => getVirtualNumber(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useExtraFee = (date, page, reason) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&page=${page || 1}`;
    reason.forEach((rs) => {
        query += `&reasons=${rs}`;
    });
    return useQuery({
        queryKey: ["extra", query],
        queryFn: () => getExtraCost(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useServers = (date, country) => {
    let query = `bought_start_date=${date[0]}&bought_end_date=${date[1]}`;
    country.forEach((cr) => {
        query += `&country=${cr}`;
    });
    return useQuery({
        queryKey: ["servers", query],
        queryFn: () => getVirtualServers(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const usePartnerShareReport = (
    date,
    platform,
    page,
    status,
    extraDate,
    partner,
    teacher,
    mentor,
    size = 10,
    enable
) => {
    let query = `page=${page || 1}&page_size=${size}`;
    if (date.length > 0) {
        const startDate = new DateObject({
            date: date[0],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        const endDate = new DateObject({
            date: date[1],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        query += `&start_date=${startDate.replaceAll("/", "-")}&end_date=${endDate.replaceAll(
            "/",
            "-"
        )}`;
    }
    if (extraDate.length > 0) {
        const startPayDate = new DateObject({
            date: extraDate[0],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        const endPayDate = new DateObject({
            date: extraDate[1],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        // const endDate = dateConvert(extraDate[1], gregorian, gregorian_en);
        query += `&registered_date_start=${startPayDate.replaceAll(
            "/",
            "-"
        )}&registered_date_end=${endPayDate.replaceAll("/", "-")}`;
    }
    if (teacher.length > 0) {
        teacher.map((tch) => {
            query += `&teacher_uuids=${tch}`;
        });
    }
    if (mentor.length > 0) {
        mentor.map((mnt) => {
            query += `&mentor_ids=${mnt}`;
        });
    }
    if (status !== undefined) {
        switch (status) {
            case 1:
                break;
            case 2:
                query += `&paid_status=false`;
                break;
            case 3:
                query += `&paid_status=true`;
                break;

            default:
                break;
        }
    }
    if (partner.length > 0) {
        partner.map((pr) => {
            query += `&partner_ids=${pr}`;
        });
    }
    if (platform.length > 0) {
        platform.map((pl) => {
            query += `&platform_uuids=${pl}`;
        });
    }
    return useQuery({
        queryKey: ["partner-report", query],
        queryFn: () => getPartnerShareReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: enable,
    });
};

const usePartnerSoldReport = (date, platform, page, status, extraDate, size = 10) => {
    let query = `page=${page || 1}&page_size=${size}`;
    if (date) {
        const startDate = new DateObject({
            date: date[0],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        const endDate = new DateObject({
            date: date[1],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        query += `&start_date=${startDate.replaceAll("/", "-")}&end_date=${endDate.replaceAll(
            "/",
            "-"
        )}`;
    }
    if (extraDate) {
        const startPayDate = new DateObject({
            date: extraDate[0],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        const endPayDate = new DateObject({
            date: extraDate[1],
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
        })
            .convert(gregorian, gregorian_en)
            .format();
        // const endDate = dateConvert(extraDate[1], gregorian, gregorian_en);
        query += `&pay_datetime_start=${startPayDate.replaceAll(
            "/",
            "-"
        )}&pay_datetime_end=${endPayDate.replaceAll("/", "-")}`;
    }
    if (status !== undefined) {
        switch (status) {
            case 1:
                break;
            case 2:
                query += `&paid_status=0`;
                break;
            case 3:
                query += `&paid_status=1`;
                break;

            default:
                break;
        }
    }
    if (platform.length > 0) {
        platform.map((pl) => {
            query += `&platforms=${pl}`;
        });
    }
    return useQuery({
        queryKey: ["partner-report", query],
        queryFn: () => getPartnerCodeReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useProfitReport = (
    date,
    platform,
    page,
    user,
    currency,
    status,
    teacher,
    partner,
    unique,
    type
) => {
    let query = `start_date=${date[0]}&end_date=${date[1]}&currency_type=${
        currency || "USD"
    }&page=${page || 1}`;
    if (user !== undefined) {
        query += `&user_uuid=${user}`;
    }
    if (type) {
        query += `&type=${type}`;
    }
    if (unique) query += "&unique=true";
    if (status !== undefined) {
        switch (status) {
            case 1:
                query += `&status=submit`;
                break;
            case 2:
                query += `&status=pending`;
                break;
            case 3:
                query += `&status=complete`;
                break;

            default:
                break;
        }
    }
    if (platform.length > 0) {
        platform.map((pl) => {
            query += `&platforms=${pl}`;
        });
    }
    if (teacher.length > 0) {
        teacher.map((tch) => {
            query += `&teacher_uuids=${tch}`;
        });
    }
    if (partner.length > 0) {
        partner.map((pt) => {
            query += `&partner_ids=${pt}`;
        });
    }
    if (currency !== undefined) {
        query += `&curreny_type=${currency}`;
    }
    return useQuery({
        queryKey: ["profit-report", query],
        queryFn: () => getProfitReport(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useUserSearch = (userEmail) => {
    return useQuery({
        queryKey: ["user-search", userEmail],
        queryFn: () => getUserSearch("", "", "", "", userEmail),
        staleTime: 6 * 60 * 60 * 1000,
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: userEmail?.length >= 4,
    });
};

const useIncome = (date) => {
    return useQuery({
        queryKey: ["financial-income"],
        queryFn: () => getFinancialProfitSummary(date),
        staleTime: 6 * 60 * 60 * 1000,
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};

const useBankInfo = (fname, phone, email, page, enable) => {
    let query = "";
    if (fname) query += `first_name=${fname}&`;
    if (phone) query += `phone=${phone}&`;
    if (email) query += `email=${email}&`;
    query += `page=${page}`;

    return useQuery({
        queryKey: ["bank-info", query],
        queryFn: () => getBankInfo(query),
        staleTime: 6 * 60 * 60 * 1000, //6 hours
        gcTime: 1 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: enable,
    });
};

export {
    useSubscriptionReport,
    useIncome,
    useProfitReport,
    useUserSearch,
    usePartnerSoldReport,
    useTopUsers,
    useServers,
    useFreelancers,
    useExtraFee,
    useVirtualNumber,
    useEmployeeSalary,
    useTickets,
    useTicketsTime,
    usePartnerShareReport,
    useClosedTickets,
    useWalletBalance,
    useTransactionHistory,
    useUpdateHistory,
    useDepartmentInfraServer,
    useDepartmentFinancial,
    useDepartmentAccounts,
    useBankInfo,
    useUsersWithdrawal,
    useUsersIncome,
    useBscWalletBalance,
    useGetUserInfo,
};
