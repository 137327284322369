import axios from "axios";
import { requestByFilteredDate } from "./http-requests";

// "GET" Requests
// export function getUsers(uuid, pageNumber) {
//   // get all users
//   if (uuid === undefined) return axios.get(`/account/user?page=${pageNumber}`);

//   //   get one user
//   return axios.get(`/account/user/?uuid=${uuid}`);
// }

export function getUsers(searchParams) {
    // get all users
    if (searchParams) return axios.get(`/account/user?${searchParams}`);

    //   get one user
    return axios.get(`/account/user`);
}

export function getPayments(pageNumber) {
    return axios.get(`/orders/payment/?page=${pageNumber}`);
}

export function getPaypalAccounts() {
    return axios.get("/paypal/accounts/");
}

export function getAllTransactions(uuid, searchParams) {
    if (searchParams) {
        return axios.get(`/paypal/accounts/${uuid}/transactions?${searchParams}`);
    }

    return axios.get(`/paypal/accounts/${uuid}/transactions`);
}

// START ==> Filter Request

export function getUsersByUserId(userId, pageNumber, url) {
    return axios.get(`${url}?user=${userId}&page=${pageNumber}`);
}

export function getUsersByFromDate(fromDate, pageNumber, url) {
    return axios.get(`${url}?from_date=${fromDate}&page=${pageNumber}`);
}

export function getUsersByToDate(toDate, pageNumber, url) {
    return axios.get(`${url}?to_date=${toDate}&page=${pageNumber}`);
}

export function getUsersByFromDateAndToDate(fromDate, toDate, pageNumber, url) {
    return axios.get(`${url}?from_date=${fromDate}&to_date=${toDate}&page=${pageNumber}`);
}

export function getUsersByUserIdAndFromDateAndToDate(userId, fromDate, toDate, pageNumber, url) {
    return axios.get(
        `${url}?user=${userId}&from_date=${fromDate}&to_date=${toDate}&page=${pageNumber}`
    );
}

export function getUsersByUserIdAndFromDate(userId, fromDate, pageNumber, url) {
    return axios.get(`${url}?user=${userId}&from_date=${fromDate}&page=${pageNumber}`);
}

export function getUsersByUserIdAndToDate(userId, toDate, pageNumber, url) {
    return axios.get(`${url}?user=${userId}&to_date=${toDate}&page=${pageNumber}`);
}

export function filterUsersByDateOrId(userId, fromDate, toDate, pageNumber) {
    if (userId === undefined) {
        return axios.get(
            `/orders/payment?from_date=${fromDate}&to_date=${toDate}&page=${pageNumber}`
        );
    }

    if (fromDate === undefined && toDate === undefined) {
        return axios.get(`/orders/payment?user=${userId}&page=${pageNumber}`);
    }

    return axios.get(
        `/orders/payment?user=${userId}&from_date=${fromDate}&to_date=${toDate}&page=${pageNumber}`
    );
}

// END ==> Filter Request

export function getUsersCalendar(month, year, platform) {
    return axios.get(`/dashboard/admin/calendar?month=${month}&year=${year}&platform=${platform}`);
}

export function getAllNotifications(pageNumber) {
    return axios.get(`/dashboard/admin/notifications?page=${pageNumber}`);
}

export function getUserSearch(platform, packageId, active, phone, email) {
    return axios.get(
        `/account/user/search?${platform !== undefined ? `platform=${platform}&` : ""}${
            packageId !== undefined ? `package=${packageId}&` : ""
        }${active !== null ? `active=${active}&` : ""}${phone !== "" ? `phone=${phone}&` : ""}${
            email !== "" ? `email=${email}` : ""
        }`
    );
}

export function getAllUserSearch() {
    return axios.get("/account/user/search");
}

export function departmentAnalysis(searchParams) {
    return axios.get(`/dashboard/departments/analyze${searchParams}`);
}

export function getAllTickets(searchParams) {
    if (searchParams) return axios.get(`/dashboard/ticket?${searchParams}`);
    // if (pageNumber && !search)
    //   return axios.get(`/dashboard/ticket?page=${pageNumber || 1}`);

    // if (search)
    //   return axios.get(
    //     `/dashboard/ticket?page=${pageNumber || 1}&search=${search}`
    //   );

    return axios.get(`/dashboard/ticket`);
}

export function getAdminReport() {
    return axios.get("/crm/main-page-report/");
}
export function getFinancialProfitSummary(date) {
    return axios.get(`crm/profits-summary/?start_date=${date[0]}&end_date=${date[1]}`);
}

export function getFinancialCostSummary(date) {
    return axios.get(`crm/costs-summary/?start_date=${date[0]}&end_date=${date[1]}`);
}

export function getSubscriptionReport(parameters) {
    return axios.get(`crm/subscription-report/?${parameters}`);
}

export function getProfitReport(parameters) {
    return axios.get(`crm/withdraw-report/?${parameters}`);
}

export function getPartnerCodeReport(parameters) {
    return axios.get(`crm/partner-sold-report/?${parameters}`);
}

export function getVirtualServers(parameters) {
    return axios.get(`crm/virtual-servers/?${parameters}`);
}

export function getFreelancerCost(parameters) {
    return axios.get(`crm/api/user-withdraws/?${parameters}`);
}

export function getExtraCost(parameters) {
    return axios.get(`crm/api/fintransactions/?${parameters}`);
}

export function getVirtualNumber(parameters) {
    return axios.get(`crm/api/number_report/?${parameters}`);
}

export function getEmployeeSalary(parameters) {
    return axios.get(`crm/api/salary-factors/?${parameters}`);
}

export function getTicketReport(parameters) {
    return axios.get(`dashboard/api/ticket-stats/?${parameters}`);
}

export function getClosedTicketRepoprt(parameters) {
    return axios.get(`crm/closed-tickets/?${parameters}`);
}

export function getTicketTimeReport(parameters) {
    return axios.get(`dashboard/api/ticket-time-stats/?${parameters}`);
}

export function getPartnerShareReport(parameters) {
    return axios.get(`crm/partner-share/?${parameters}`);
}

export function getDepartmentInfraServer(parameters) {
    return axios.get(`/dashboard/api/department-average-response-time/?${parameters}`);
}

export function getDepartmentFinancial(parameters) {
    return axios.get(`/dashboard/process-requests/avg-time/?${parameters}`);
}

export function getDepartmentAccounts(parameters) {
    return axios.get(`/dashboard/process-requests/metrics/?${parameters}`);
}

export function getCompareDates(date1, date2) {
    return axios.get(
        `/account/active-users-in-date-range/?first_date_time=${date1}&second_date_time=${date2}`
    );
}

export function getUsrsWithdrawals(parameters) {
    return axios.get(`/account/users_by_teacher_uuid/?${parameters}`);
}

export function getUsrsIncomes(parameters) {
    return axios.get(`/account/users_withdraw_summary/?${parameters}`);
}

export function getTeacherSummary(teacher_uuid) {
    return axios.get(`/account/teacher_share_summary?teacher_uuid=${teacher_uuid}`);
}

// PUT Requests
export function updateUser(userData) {
    return axios.patch("/account/user/", userData);
}

// POST Request
export function postMessage(message) {
    return axios.post("/dashboard/admin/notifications", message);
}

export function postFactor(month, year, factor) {
    return axios.post(`/dashboard/admin/calendar?month=${month}&year=${year}`, factor);
}

export function postPaypalTransaction(data) {
    return axios.post("/paypal/transactions/", data);
}

export function changeTicketStatus(uuid, status) {
    return axios.post(`/dashboard/api/tickets/${uuid}/change-status/`, {
        status: status,
    });
}

export function changePartnerCodeStatus(status, ids) {
    return axios.post(`crm/partner-sold-report/`, {
        paid_status: status,
        request_ids: ids,
    });
}

export function changePartnerShareStatus(partnerStatus, teacherStatus, mentorStatus, ids) {
    return axios.post(`crm/partner-share/`, {
        is_partner_share_paid: partnerStatus,
        is_teacher_share_paid: teacherStatus,
        is_mentor_share_paid: mentorStatus,
        process_request_ids: ids,
        teacher_share: true,
        partner_share: true,
        mentor_share: true,
    });
}

export function updateEmployeeSalary(ids) {
    return axios.post(`crm/api/salary-factors/calculate-amount-to-be-paid/`, {
        ids: ids,
    });
}

export function teacherSettlement(data) {
    return axios.post("/account/teacher_share_summary", data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

// export function teacherSettlement(teacher, month) {
//     return axios.post("/account/teacher_share_summary/", {
//         teacher_uuid: teacher,
//         jalali_month: month
//     });
// }

// PATCH Request
export function referenceTicket(departmentId, ticketUuid) {
    return axios.patch("/dashboard/ticket", {
        department: departmentId,
        ticket: ticketUuid,
    });
}

export function updatePaySalary(id, amount) {
    return axios.put(`crm/api/salary-factors/${id}/update-amount-paid/`, {
        amount_paid: amount,
    });
}

export function ticketManagementEdit(tktId, msgId, text) {
    return axios.patch("/dashboard/api/message-management", {
        ticket_id: tktId,
        message_id: msgId,
        text,
    });
}

export function ticketManagementDelete(tktId, msgId) {
    return axios.delete("/dashboard/api/message-management", {
        data: { ticket_id: tktId, message_id: msgId },
    });
}
