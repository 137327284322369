import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import CurrentUserContextProvider from "context/current-user-provider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryclient = new QueryClient();
root.render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId="698867028064-713dmg8bgbbqtbanqqr50eekdbqn6j1l.apps.googleusercontent.com">
        <QueryClientProvider client={queryclient}>
            <CurrentUserContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CurrentUserContextProvider>
        </QueryClientProvider>
    </GoogleOAuthProvider>
    // </React.StrictMode>
);
