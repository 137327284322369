import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import AllRoutes from "routes/routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useIdleTimer } from "react-idle-timer";
import { logoutApi } from "services/http-requests";

function App() {
    const logOutAccount = () => {
        logoutApi()
            .then(() => {
                localStorage.removeItem("auth-user");
                window.location.href = "/login";
            })
            .catch(() => toast.error("خطا در خروج از حساب کاربری"));
    };

    const queryclient = new QueryClient();
    const onIdle = () => {
        logOutAccount();
    };

    useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 60 * 3, // 3 hours
    });

    return (
        <div>
            <ToastContainer
                className="font-yekan-600"
                bodyClassName={() => "text-15 font-yekan-bakh-600 flex p-2 items-center"}
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            {/* <QueryClientProvider client={queryclient}> */}
            <AllRoutes />
            {/* <ReactQueryDevtools /> */}
            {/* </QueryClientProvider> */}
        </div>
    );
}

export default App;

// git remote add origin https://github.com/Mohammad-SS/gpanel-frontend.git
