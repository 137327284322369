import { useGetUserInfo } from "hooks/queries";
import React, { useContext, createContext, useState, useEffect } from "react";
import { getUserInfo } from "services/http-requests";

export const CurrentUserContext = createContext();

const CurrentUserContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    const { data, isSuccess } = useGetUserInfo();

    // const getCurrentUser = async () => {
    //     await getUserInfo().then((response) => {
    //         setCurrentUser(response.data.object);
    //     });
    // };

    useEffect(() => {
        isSuccess && setCurrentUser(data.data.object);
    }, [isSuccess]);

    return (
        <CurrentUserContext.Provider value={{ setCurrentUser, currentUser }}>
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserContextProvider;

export const useCurrentUser = () => useContext(CurrentUserContext);
